import { computed, ref } from "vue";

import { useStore } from "vuex";

const usePaystack = () => {
  const reference = ref(Math.floor(Math.random() * 1000000000 + 1));
  const embedRef = ref(null);

  const store = useStore();

  const authEmail = computed(() => store.getters.currentUser.email);

  const refreshReference = () => {
    reference.value = Math.floor(Math.random() * 1000000000 + 1);
  };

  return { reference, refreshReference, embedRef, authEmail };
};

export default usePaystack;

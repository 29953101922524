
import { defineComponent, ref, PropType, computed, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { APIKey } from "@/assets/ts/_utils/models/APIKeyModel";
import PlansList from "./PlansList.vue";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { hideModal } from "@/core/helpers/dom";
import useCurrencies from "@/core/composables/useCurrencies";

enum View {
  FORM = "FORM",
  PLANS = "PLANS",
}

export default defineComponent({
  name: "create-api-key-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
    PlansList,
  },
  props: {
    plans: { type: Array as PropType<Plan[]>, default: () => [] },
    applyFeeFn: { type: Function, required: true },
    fees: { type: String },
    loading: { type: Boolean },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const { getPlanPriceByCurrency } = useCurrencies();

    const activeView = ref<View>(View.FORM);

    const apiData = ref<APIKey>({
      name: "",
      plan: undefined,
    });

    const isPlansVisible = computed(() => {
      return activeView.value === View.PLANS;
    });

    const validationSchema = computed(() => {
      if (!isPlansVisible.value) {
        return Yup.object().shape({
          name: Yup.string().required().label("API key name"),
        });
      }
      return Yup.object().shape({
        plan: Yup.number().required().label("Plan"),
      });
    });

    const selectedPlan = computed(() => {
      if (!apiData.value.plan) {
        return null;
      }
      return props.plans.find((p) => p.id === apiData.value.plan);
    });

    const totalPrice = computed(() => {
      if (!selectedPlan.value) {
        return 0;
      }
      return (
        getPlanPriceByCurrency(selectedPlan.value, "usd") +
        props.applyFeeFn(+(selectedPlan.value.price || 0))
      );
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      emit("submit", {
        payload: apiData.value,
        plan: selectedPlan.value,
        total: totalPrice.value,
      });

      hideModal(createAPIKeyModalRef.value);
      apiData.value = {
        plan: undefined,
        name: "",
      };
      activeView.value = View.FORM;
      (formRef.value as any).resetForm();
    };

    watch(
      () => apiData.value?.plan,
      (curVal, oldVal) => {
        (formRef.value as any).setFieldValue("plan", curVal);
      }
    );

    return {
      apiData,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createAPIKeyModalRef,
      View,
      activeView,
      isPlansVisible,
      totalPrice,
      formRef,
    };
  },
});

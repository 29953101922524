
import { defineComponent, ref, PropType } from "vue";

import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import { cloneDeep } from "lodash";
import useCurrencies from "@/core/composables/useCurrencies";

export default defineComponent({
  name: "plan-list",
  components: {},
  emits: ["update:plan"],
  props: {
    plans: { type: Array as PropType<Plan[]>, default: () => [] },
    plan: { type: Number },
  },
  setup(props, { emit }) {
    const selected = ref<number | null | undefined>(props.plan);
    const { getPlanPriceByCurrency } = useCurrencies();

    const selectPlan = (plan) => {
      selected.value = plan;
      emit("update:plan", plan);
    };

    return {
      selected,
      selectPlan,
      getPlanPriceByCurrency,
    };
  },
});


import { defineComponent, ref, onMounted, watch } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";

import { debounce } from "lodash";
import useNotify from "@/core/composables/notify";
import usePaystack from "@/core/composables/usePaystack";
import { IDVerification } from "@/assets/ts/_utils/models/IDVerification";
import { useStore } from "vuex";
import APIKeyModal from "../modals/APIKeyModal.vue";
import { PlanType } from "@/assets/ts/_utils/enums/plan.enum";
import useGetPlans from "@/core/composables/getPlans";
import { Plan } from "@/assets/ts/_utils/models/PlanModel";
import useFees from "@/core/composables/useFees";
import { APIKey } from "@/assets/ts/_utils/models/APIKeyModel";
import Paystack from "./Paystack.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Notice from "../Notice.vue";
import { hideModal, showModal } from "@/core/helpers/dom";
import { AppModal } from "@/assets/ts/_utils/enums/modals.enum";
import StripeModal from "@/components/modals/StripeModal.vue";

export default defineComponent({
  name: "api-keys",
  components: {
    Datatable,
    APIKeyModal,
    // Paystack,
    Notice,
    StripeModal,
  },
  setup() {
    const selectedRow = ref<IDVerification | null>(null);
    const route = useRoute();
    const { toastErrorRaw } = useNotify();
    const { reference, embedRef, authEmail, refreshReference } = usePaystack();

    const { data: plans, loading: loadingPlans } = useGetPlans({
      plan_type: PlanType.API_KEYS,
    });
    const selectedPlan = ref<Plan | null>(null);
    const totalPrice = ref<number>();
    const apiKeyPayload = ref<APIKey>();
    const stripeSecret = ref("");

    const { data: fees, applyFees, feeBreakdown } = useFees();
    const store = useStore();

    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Key",
        key: "key",
        sortable: true,
      },
      {
        name: "Limit",
        key: "limit",
      },
      {
        name: "No of uses",
        key: "no_uses",
      },

      {
        name: "Scope",
        key: "services_display",
      },
      {
        name: "Actions",
        key: "edit",
      },
    ]);

    const tableData = ref<Array<IDVerification>>([]);
    const tableKey = ref(1);

    const fetchData = async (search?: string) => {
      try {
        tableData.value = [];

        const params: any = {};
        if (search) {
          params.search = search;
        }

        const resp = await ApiService.query("api-keys/", {
          params,
          hideLoader: !!search,
        });
        tableData.value = resp.data.results;
        setCurrentPageBreadcrumbs("API Keys", ["Api Keys"]);
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    watch(
      () => route.params,
      (curVal, oldVal) => {
        if (curVal != oldVal && store.getters.currentUser?.id) {
          fetchData();
        }
      }
    );

    const search = ref<string>("");
    const searchItems = debounce(() => {
      fetchData(search.value);
    }, 1000);

    const handleSubmit = async ({ payload, plan, total }) => {
      reference.value = Math.floor(Math.random() * 1000000000 + 1);
      selectedPlan.value = plan;
      apiKeyPayload.value = payload;
      totalPrice.value = total;
      const resp = await ApiService.post("initialize-payment/", {
        amount: total,
        description: `Payment for API Key`,
      } as any);
      stripeSecret.value = resp.data;
      showModal(AppModal.STRIPE_MODAL);
      // open modal
      // (embedRef.value as any).payWithPaystack();
    };

    const processPayStackPayment = async (providerPayment?) => {
      console.log("providerPayment :>> ", providerPayment);
      try {
        const payment = await ApiService.post("payments/", {
          company: store.getters.currentUser.id,
          reference_number:
            providerPayment?.paymentIntent?.id || reference.value,
          gateway: "STRIPE",
          currency: "Dollar",
          amount:
            providerPayment?.paymentIntent?.amount ||
            Math.round((totalPrice.value || 0) * 100),
        } as any);

        const apiKey = await ApiService.post("api-keys/", {
          ...apiKeyPayload.value,
          payment: payment.data?.id,
          plan: selectedPlan.value?.id,
        } as any);

        refreshReference();

        await Swal.fire({
          title: "Key Created!",
          text: "You will be able to view your key from the keys list at any time",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Close",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
          html: `<div class="bg-light border rounded p-6">${apiKey.data?.key}</div> `,
        });
        await fetchData();
        hideModal(AppModal.STRIPE_MODAL);
      } catch (error: any) {
        toastErrorRaw(error);
      }
    };

    const copyKey = (e, apiKey) => {
      navigator?.clipboard.writeText(apiKey.key);
      if (e.target) {
        e.target.innerHTML = "Copied";
      }
    };

    const revealKey = (e, apiKey) => {
      if (e.target) {
        e.target.innerHTML = apiKey;
      }
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      selectedRow,
      tableKey,
      plans,
      fees,
      applyFees,
      handleSubmit,
      totalPrice,
      feeBreakdown,
      reference,
      embedRef,
      authEmail,
      processPayStackPayment,
      copyKey,
      loadingPlans,
      revealKey,
      user: store.getters.currentUser,
      stripeSecret,
    };
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b89540e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "bg-primary ribbon-label rounded" }
const _hoisted_3 = {
  key: 0,
  class: "mt-2 text-muted d-block"
}
const _hoisted_4 = {
  key: 1,
  class: "d-flex gap-3 mt-4 flex-wrap w-75"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan, idx) => {
    return (_openBlock(), _createElementBlock("div", {
      key: idx,
      class: _normalizeClass(["feature-card mb-6 p-4 ribbon rounded", { selected: _ctx.selected === plan.id }]),
      onClick: ($event: any) => (_ctx.selectPlan(plan.id))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$filters.formatCurrency(_ctx.getPlanPriceByCurrency(plan, "usd"), "usd")), 1),
      _createElementVNode("div", null, _toDisplayString(plan.name), 1),
      (plan.detail)
        ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(plan.detail), 1))
        : _createCommentVNode("", true),
      (plan.features.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(plan.features, (feature) => {
              return (_openBlock(), _createElementBlock("span", {
                class: "badge badge-light-primary",
                key: feature.id
              }, _toDisplayString(feature.name), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1))
  }), 128))
}